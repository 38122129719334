import * as React from 'react';
import * as styles from './quiz-template-category.module.scss';

import { Link, graphql } from 'gatsby';

import Callout from '../components/v2/callout';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import LazyImage from '../components/lazy-img';
import SEO from '../components/seo';
import Section from '../components/section';

const QuizTemplateCategoryTemplate = ({ data, pageContext }) => {
  const { name } = pageContext;
  return (
    <>
      <SEO
        title={`${name} Quizzes`}
        description={`Take ${name} quizzes. Browse ${name} quizzes, answer questions, and test yourself.`}
      />
      <Header btnLabel="Make a quiz" btnLink="/" btnTheme="primary" btnType="internal" />
      <main className={styles.templateCategory}>
        <Section style={{ marginTop: `4.5rem`, paddingTop: `2rem` }}>
          <div className="row">
            <div className="col-lg-12">
              <ul className={styles.nav} style={{ marginBottom: `3rem` }}>
                <li>
                  <Link to="/quizzes/">Quizzes</Link>
                </li>
                <li className={styles.separator}>/</li>
                <li>{name} Quizzes</li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 text-center">
              <h1 className="heading-xl color-black">{name} Quizzes</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2 className="text-body color-muted-foreground">
                Browse <span style={{ textTransform: `lowercase` }}>{name}</span> quizzes, answer
                questions, and test yourself.
              </h2>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row">
            {data.quizTemplates.edges.map(({ node }) => {
              const { title, slug, img, n_questions } = node.data;
              return (
                <div className="col-lg-4 col-md-6" style={{ marginBottom: `1.5rem` }} key={slug}>
                  <Link to={`/quiz/${slug}/`}>
                    <div className={styles.card}>
                      <LazyImage className={styles.img} activeClassName={styles.active} src={img} />
                      <div className={styles.content}>
                        <p className="text-body color-black">{title}</p>
                        <p>{n_questions} questions</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default QuizTemplateCategoryTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    quizTemplates: allAirtable(
      filter: {
        table: { eq: "Templates" }
        data: { active: { eq: true }, featured_category: { eq: $slug } }
      }
      sort: { fields: data___created_at, order: DESC }
    ) {
      edges {
        node {
          data {
            title
            slug
            img
            n_questions
          }
        }
      }
    }
  }
`;
